import { ReportTemplate } from "@prisma/client";
import { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useTranslation } from "react-i18next";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";
import { ReportTemplateWithUser } from "src/server-ts/super-admin/report-templates/SuperAdminReportTemplateQueries";

const baseUrl = "/super-admin/report-templates";

export interface ARJSReportTemplateWithUser extends ReportTemplateWithUser {
  template: ARJS.Report;
}

export function useGetReportTemplates(): UseQueryResult<
  ARJSReportTemplateWithUser[]
> {
  return useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate[]>(baseUrl);
      return data;
    },
  });
}

export function useCreateReportTemplate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      name,
      template,
    }: Pick<ReportTemplate, "name" | "template">) => {
      const { data } = await axiosClient.post<ReportTemplate>(baseUrl, {
        name,
        template,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [baseUrl] });
    },
  });
}

export function useGetReportTemplateByName(
  name: string | undefined
): UseQueryResult<ARJSReportTemplateWithUser> {
  const { t } = useTranslation("reports", {
    keyPrefix: "reportDesigner",
  });
  return useQuery({
    queryKey: [baseUrl, name],
    enabled: !!name,
    queryFn: async () => {
      const { data } = await axiosClient.get<ReportTemplate>(
        `${baseUrl}/${name}`
      );
      return data;
    },
    onError: (error: AxiosError) => {
      reportError(t("fetchFailed"), error);
    },
  });
}

export function useUpdateReportTemplate() {
  return useMutation({
    mutationFn: async ({
      name,
      template,
    }: Pick<ReportTemplate, "name" | "template">) => {
      const { data } = await axiosClient.put<ReportTemplate>(
        `${baseUrl}/${name}`,
        { template: template }
      );
      return data;
    },
    onError: (err: AxiosError) => {
      reportError(`Error while trying to save report template`, err);
    },
  });
}

export function useDeleteReportTemplate() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: number) => {
      return axiosClient.delete(`${baseUrl}/${id}`);
    },
    {
      onError: (err: AxiosError) => {
        reportError(`Error while trying to delete report template`, err);
      },
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: [baseUrl] });
      },
    }
  );
}
