import { ReportTemplate } from "@prisma/client";
import { Loader } from "@tocoman/ui";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateReportTemplateDialog from "./components/CreateReportTemplateDialog";
import { useGetReportTemplates } from "./hooks/useReportTemplates";
import { ReportTemplateActionsButton } from "./components/ReportTemplateActionsButton";
import { ConfirmDeleteReportTemplateModal } from "./components/ConfirmDeleteReportTemplateModal";

type DeleteReportTemplate = {
  open: boolean;
  template?: ReportTemplate;
};

export const ReportTemplateList = () => {
  const { t } = useTranslation("superAdmin", { keyPrefix: "reportDesigner" });
  const { data: templates, isLoading, isError } = useGetReportTemplates();

  const [deleteReportTemplate, setDeleteReportTemplate] = useState<
    DeleteReportTemplate
  >({
    open: false,
  });

  const openDeleteReportTemplateView = useCallback(
    (template: ReportTemplate) => {
      setDeleteReportTemplate({
        open: true,
        template: template,
      });
    },
    [setDeleteReportTemplate]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full py-2">
        <CreateReportTemplateDialog />
      </div>

      {isError && (
        <div className="text-red-500 text-sm">{t`errorLoadingTemplates`}</div>
      )}

      {isLoading && <Loader isLoading={true} kind="spinner" />}

      {(!templates || templates.length === 0) && <div>{t`noTemplates`}</div>}

      {templates && templates.length > 0 && (
        <ul className="flex flex-col gap-2">
          {templates.map((template) => (
            <li
              key={template.id}
              className="flex border border-slate-200 rounded-md p-2 gap-2 justify-between items-center"
            >
              <div className="flex flex-col gap-1">
                <span className="text-lg bold">{template.name}</span>
                <span className="text-xs">
                  {t("lastEditedBy", {
                    lastEditedBy: template.lastEditedBy.email,
                    lastEditedAt: format(
                      template.updatedAt,
                      "DD.MM.YYYY HH:mm"
                    ),
                  })}
                </span>
              </div>

              <div className="flex gap-2">
                <ReportTemplateActionsButton
                  template={template}
                  onTemplateDelete={openDeleteReportTemplateView}
                />
              </div>
            </li>
          ))}
        </ul>
      )}
      {deleteReportTemplate.open && (
        <ConfirmDeleteReportTemplateModal
          isOpen={deleteReportTemplate.open}
          closeModal={() => {
            setDeleteReportTemplate((current) => ({ ...current, open: false }));
          }}
          template={deleteReportTemplate.template}
        />
      )}
    </div>
  );
};
