import { SplitButton } from "../../../../components/SplitButton";
import { useCallback, useMemo, useState } from "react";
import {
  FileUpload,
  IconArrowDown,
  IconArrowUp,
  IconDelete24Px,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { Prisma, ReportTemplate } from "@prisma/client";
import { useUpdateReportTemplate } from "../hooks/useReportTemplates";

export function ReportTemplateActionsButton({
  template,
  onTemplateDelete,
}: {
  template: ReportTemplate;
  onTemplateDelete: (template: ReportTemplate) => void;
}) {
  const { t } = useTranslation("superAdmin", { keyPrefix: "reportDesigner" });
  const saveReportTemplate = useUpdateReportTemplate();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const openReportDesigner = (template: ReportTemplate) => {
    window.open(
      `/new/superadmin/report-templates/${template.name}/edit/`,
      "newWin",
      `width=1920,height=1024,left=${window.screen.availWidth / 2 - 960},top=${
        window.screen.availHeight / 2 - 540
      }`
    );
  };

  const setTemplateExport = async (template: ReportTemplate) => {
    const downloadTemplate = window.URL.createObjectURL(
      new Blob([JSON.stringify(template)])
    );
    const link = document.createElement("a");

    link.href = downloadTemplate;

    link.setAttribute("download", "template.json");
    link.click();
    URL.revokeObjectURL(downloadTemplate);
  };

  const importTemplate = async (name: string, template: Prisma.JsonValue) => {
    return saveReportTemplate.mutateAsync({ name, template });
  };

  const confirmImport = async (name: string) => {
    if (files.length === 0) {
      return;
    }

    const template = JSON.parse(await files[0].text());
    await importTemplate(name, template);

    setIsUploadModalOpen(false);
    setFiles([]);
  };

  const options = useMemo(
    () => [
      {
        icon: IconArrowUp,
        label: t`import.label`,
        disabled: false,
        color: "",
      },
      {
        icon: IconArrowDown,
        label: t`export`,
        disabled: false,
        color: "",
      },
      {
        icon: IconDelete24Px,
        label: t`deleteTemplateDialog.deleteBtn`,
        disabled: false,
        color: "red",
      },
    ],
    [t]
  );

  const onMenuItemClick = useCallback(
    (selectedItem: number) => {
      selectedItem === 0 && setIsUploadModalOpen(true);
      selectedItem === 1 && setTemplateExport(template);
      selectedItem === 2 && onTemplateDelete(template);
    },
    [setIsUploadModalOpen, template, setTemplateExport, onTemplateDelete]
  );

  return (
    <>
      <FileUpload
        browseButtonLabel={t`import.browseButton`}
        title={t`import.title`}
        description={t`import.description`}
        dragDropLabel={t`import.dragDrop`}
        confirmLabel={t`import.confirm`}
        cancelLabel={t`import.cancel`}
        isOpen={isUploadModalOpen}
        isLoading={false}
        onClose={() => {
          setIsUploadModalOpen(false);
          setFiles([]);
        }}
        onConfirm={() => confirmImport(template.name)}
        files={files}
        onFilesChange={(files) =>
          // Limit to one file
          files.length > 0 ? setFiles([files[0]]) : setFiles([])
        }
      />

      <SplitButton
        id={`reportDesigner-template-actions-split-button-${template.id}`}
        buttonLabel={t`edit`}
        options={options}
        onMenuItemClick={onMenuItemClick}
        onButtonClick={() => openReportDesigner(template)}
      />
    </>
  );
}
