import { useCreateOrUpdateUsersAgGridConfiguration } from "./useUsersAgGridConfigurations";
import {
  ColumnEverythingChangedEvent,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  DragStoppedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback } from "react";

export function useSaveUsersAgGridConfiguration(
  gridRef: React.RefObject<AgGridReact>,
  agGridName: string,
  projectId?: number
) {
  const saveConfiguration = useCreateOrUpdateUsersAgGridConfiguration();
  return useCallback(
    (
      event:
        | DragStoppedEvent
        | ColumnResizedEvent
        | ColumnRowGroupChangedEvent
        | ColumnVisibleEvent
        | ColumnPinnedEvent
        | ColumnEverythingChangedEvent
    ) => {
      if (event) {
        if (
          ("source" in event &&
            "finished" in event &&
            event.source === "uiColumnResized" &&
            event.finished) ||
          event.type === "dragStopped" ||
          (event.type === "columnRowGroupChanged" &&
            event.source === "toolPanelUi") ||
          (event.type === "columnVisible" && event.source === "toolPanelUi") ||
          (event.type === "columnPinned" && event.source === "contextMenu") ||
          (event.type === "columnEverythingChanged" &&
            event.source === "contextMenu")
        ) {
          const modelData = {
            agGridName: agGridName,
            agGridConfiguration: JSON.stringify(
              gridRef.current?.api.getColumnState()
            ),
            projectId: projectId ?? null,
          };
          return saveConfiguration.mutate(modelData);
        }
      }
    },
    [agGridName, gridRef, projectId, saveConfiguration]
  );
}
